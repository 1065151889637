<template>
  <section class="chat-area">
    <div v-if="isLoading" class="w-100">
      <b-spinner />
    </div>

    <div v-else-if="isChatEmpty">
      <b-img :src="placeholderImage" class="placeholder-img" center />
      <div class="mt-2 text-center">
        <p class="text-primary font-weight-500">
          {{ $t("chat.user-chat.empty-message") }}
        </p>
      </div>
    </div>

    <ChatMessages 
      :messages="chatData" 
      @msgToSend="sendMessage" 
      @msgToEdit="editMessage" 
      @msgToDelete="deleteMessage" 
    />
  </section>
</template>

<script>
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
// import PlainEditor from '@core/components/editor/PlainEditor.vue';
import ChatPlaceholder from '@/assets/images/placeholders/light/chat.svg';
import ChatMessages from './ChatMessages.vue';

export default {
  name: 'ChatArea2',

  components: {
    // PlainEditor,
    ChatMessages,
  },

  mixins: [WidgetLayoutMixin],

  data() {
    return {
      // The name of the room with the roomType included
      isLoading: false,
    };
  },
  computed: {
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    placeholderImage() {
      return ChatPlaceholder;
    },
    room() {
      return this.$store.getters.currentCollective.slug;
    },
    chatData() {
      // console.log('this.room', this.room);
      // console.log('this.$store.getters.chats', this.$store.getters.chats);
      return this.$store.getters.chats[this.room]?.unpaginated || [];
    },
    isChatEmpty() {
      return this.chatData.length === 0;
    },
  },
  sockets: {
    async received(data) {
      if (data.room !== this.$store.getters.currentCollective.slug) {
        return;
      }
      await this.$store.dispatch('setItemSocket', {
        itemType: 'chats',
        storedKey: this.$store.getters.currentCollective.slug || data.room ,
        item: data,
      });
    },
  },
  async created() {
    // Socket: Join chat room for the space:
    const slug = this.$store.getters.currentCollective.slug;
    
    // Ensure slug is a string
    let safeSlug = slug;
    if (typeof safeSlug === 'object' && safeSlug !== null) {
      console.warn('ChatArea2 - slug is an object:', JSON.stringify(safeSlug));
      safeSlug = safeSlug.slug || safeSlug.id || '';
    }
    safeSlug = String(safeSlug || '');
    console.log('ChatArea2 - joining socket room with safeSlug:', safeSlug);
    
    this.$socket.emit('join', safeSlug);
    // Get messages from the previous room
    await this.fetchData();
  },
  methods: {
    sendMessage(inputMsg) {
      console.log('Creating message from ChatArea2:', inputMsg);

      let chatInputMessageWithoutSpaces = inputMsg;
      const wordsToReplace = [' ', '<br>', '<p>', '</p>'];
      wordsToReplace.forEach((word) => {
        chatInputMessageWithoutSpaces = chatInputMessageWithoutSpaces.replaceAll(word, '');
      });
      // Don't send empty messages
      if (chatInputMessageWithoutSpaces.length === 0) {
        return;
      }
      // Socket: Save message
      this.$socket.emit('message', {
        communitySlug: this.$store.getters.currentCollective.slug,
        msg: inputMsg,
        memberKey: this.loggedMember.key,
      });

      console.log('message emitted through socket...');
    },
    editMessage(data) {
      console.log('Editing message from ChatArea2:', data);

      let chatInputMessageWithoutSpaces = data.message;
      const wordsToReplace = [' ', '<br>', '<p>', '</p>'];
      wordsToReplace.forEach((word) => {
        chatInputMessageWithoutSpaces = chatInputMessageWithoutSpaces.replaceAll(word, '');
      });

      console.log('messageID', data.messageID);
      console.log('message', data.message);

      // Socket: Edit message
      this.$socket.emit('edit-message', {
        communitySlug: this.$store.getters.currentCollective.slug,
        messageID: data.messageID,
        memberKey: this.loggedMember.key,
        msg: data.message,
      });

      console.log('edit-message emitted through socket...');
    },

    async deleteMessage(messageID) {
      console.log('Deleting message from ChatArea2:', messageID);

      // Socket: Delete message
      this.$socket.emit('delete-message', {
        communitySlug: this.$store.getters.currentCollective.slug,
        messageID: messageID,
        memberKey: this.loggedMember.key,
      });

      console.log('delete-message emitted through socket...');

      await this.fetchData();

        // try {
        //   await this.$store.dispatch('postItem', {
        //     type: 'deleteChatMessage',
        //     customName: 'chatMessage',
        //     forceUpdate: true,
        //     requestConfig: {
        //       messageID: this.messageID,
        //     },
        //   });
        //   this.isVisible = false;
        //   await this.getInitialData();
        //   this.$toast({
        //     component: ToastificationContentVue,
        //     props: {
        //       title: this.$t('chat.messages.success-remove'),
        //       icon: 'CheckIcon',
        //       variant: 'success',
        //     },
        //   });
        //   this.$emit('removed', this.messageID);
        // } catch {
        //   this.$toast({
        //     component: ToastificationContentVue,
        //     props: {
        //       title: this.$t('unshare.toast-messages.error', {
        //         item: this.$t('unshare.singular-items.entity'),
        //       }),
        //       icon: 'AlertTriangleIcon',
        //       variant: 'danger',
        //     },
        //   });
        // }
    },
    async fetchData() {
      this.isLoading = true;
      await this.$store.dispatch('getItemsSocket', {
        itemType: 'chats',
        storedKey: this.room ?? this.$store.getters.currentCollective.slug,
        perPage: 100,
        requestConfig: {
          room: this.room,
        },
      });
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.chat-area {
  height: calc(100% - 52px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.placeholder-img {
  width: 120px;
  display: block;
}
</style>
